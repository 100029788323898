import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { AlertTiny, Animate, Button, DualList, Loading, PageTitle, Panel, Row, Select, Tabs, TextInformation, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import { downloadTxt } from '@optimuminterfaces/revex-react-components/dist/utils/FileUtil';

import { Employee } from '../../../models/Employee';
import { fetchExportFile, fetchExportFileAFD, fetchExportFileAFDT } from '../../../services/ExportFile.services';
import { calculateSelectMonth, calculateSelectYear } from '../../../utils/SelectUtil';

import styles from './ExportFile.module.scss';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

interface ExportFileProps{
	Container?: any
}
const ExportFile = ({Container='div'}: ExportFileProps) => {
	const years = calculateSelectYear();
	const months = calculateSelectMonth(years[3].value);

    let currentMonth = months.filter(m => m.selected==true)[0];

	const [exportFileStatus, setExportFileStatus] = useState('waiting');
	const [reps, setReps] = useState<any[]>([]);
	const [repValue, setRepValue] = useState<{key: string, value: string, selected: boolean}>();
	const [employees, setEmployees] = useState<any[]>([]);

	const [dualListOptions, setDualListOptions] = useState<any[]>();
	const [dualListOptionsSelected, setDualListOptionsSelected] = useState<string[]>();
	
	const [monthValue, setMonthValue] = useState(currentMonth);
    const [yearValue, setYearValue] = useState(years[3]);
    const [listYears, setListYears] = useState(years);
    const [listMonths, setListMonths] = useState(months);

	const [waitingReport, setWaitingReport] = useState(false);

	const [toastAlert, setToastAlert] = useState<{show:boolean, message: string, danger: boolean}>({show: false, message: '', danger: false})

	useEffect(() => {
		const buildSelectReps = (reps: any[]) => {
			let selectRep: { key: string; selected: boolean; value: string }[] = [];
			reps.forEach(rep => {
				selectRep.push({key: rep.id, selected: false, value: `${rep.repModel.name} - Nº de Série: ${rep.serialNumber}`})
			});
			setReps(selectRep);
		}

		const buildDualListOptions = (employees: Employee[]) => {
			let dualList: { key: string; selected: boolean; values: any[]; }[] = [];
			employees.forEach(employee => {
				dualList.push({key: employee.id, selected: false, values: [employee.name,employee.occupation?.name]})
			});
			
			setDualListOptions(dualList);
		}
		
		(async () => {
			setExportFileStatus('waiting');
			try{

				const jsonReturned =  await fetchExportFile({month: monthValue.key, year: yearValue.key});
				if(jsonReturned){
					buildSelectReps(jsonReturned.reps);
					setEmployees(jsonReturned.employees);
					buildDualListOptions(jsonReturned.employees);
					setExportFileStatus('success');
				}else{
					setExportFileStatus('error');
				}
			}catch(error){
				console.log(error);
			}

		})();
	},[monthValue, yearValue]);

	useEffect(() => {
		if(yearValue !== undefined){
			const m = calculateSelectMonth(yearValue.key);
			setListMonths(m);
			changeSelectMonth(monthValue.key,m);
		}
	},[yearValue]);

	const changeSelectMonth = (key: string, ms?: any[]) => {
        let months = ms?[...ms]:[...listMonths];

        for (var i = 0; i < months.length; i++) {
            if (months[i].key === key) {
                months[i].selected = true;
                setMonthValue(months[i]);
            } else {
                months[i].selected = false;
            }
        }

        //setListMonths(months);

    }

	const changeSelectYear = (key: string) => {
        let years = [...listYears];

        for (var i = 0; i < years.length; i++) {
            if (years[i].key === key) {
                years[i].selected = true;
                setYearValue(years[i]);
            } else {
                years[i].selected = false;
            }
        }

        setListYears(years);

    }

	const changeSelectRep = (key: string) => {
        let repList = [...reps!];

        for (var i = 0; i < repList.length; i++) {
            if (repList[i].key === key) {
                repList[i].selected = true;
                setRepValue(repList[i]);
            } else {
                repList[i].selected = false;
            }
        }

        setReps(repList);
    };

	const handleChangeDualList = (returnOptions: any[]) => {
        let selectedEmployeeIds = returnOptions.filter((option:any) => option.selected === true).map(option => option.key);
        if (selectedEmployeeIds.length === 0) {
            setDualListOptionsSelected([]);
        } else {
            setDualListOptionsSelected(selectedEmployeeIds);
        }

    };

	const downloadReportAfd = async () => {
        setWaitingReport(true);

        let fileName = `AFD_${monthValue.value}_${yearValue.value}_${moment().format('DD-MM-YYYY_HH-mm')}.txt`;

		try{
			const jsonReturned =  await fetchExportFileAFD({year: yearValue.key, month: monthValue.key, repId: repValue?.key});
			if(jsonReturned){
				setToastAlert({...toastAlert, danger: false, message:t('EXPORT_FILES.AFD.DOWNLOAD_SUCCESSFULY',undefined),show: true});
				downloadTxt(jsonReturned, fileName);
				setWaitingReport(false);
                setRepValue(undefined);
			}
		}catch(error){
			console.log(error);
		}
    };

    const downloadReportAfdt = async () => {
        setWaitingReport(true);
        let fileName = `AFDT_${monthValue.value}_${yearValue.value}_${moment().format('DD-MM-YYYY_HH-mm')}.txt`;
        
		let jsonData = {employeeIds: dualListOptionsSelected};

		try{
			const jsonReturned =  await fetchExportFileAFDT(jsonData, { year: yearValue.key, month: monthValue.key });
			if(jsonReturned){
				setToastAlert({...toastAlert, danger: false, message:t('EXPORT_FILES.AFDT.DOWNLOAD_SUCCESSFULY',undefined),show: true});
				downloadTxt(jsonReturned, fileName);
				setWaitingReport(false);
			}
		}catch(error){
			console.log(error);
		}
    };

	const renderItemAfd = () => {
        return (
            <>
                <Row>
                    {reps !== null && reps!.length > 0 &&
                        <Animate>
                            <Row.Item lg={6} md={6} sm={6}>
                                <Select
                                    medium
                                    fullWidth
                                    title="REP"
                                    name="selectRepAfd"
                                    disabled={waitingReport}
                                    value={repValue?.value}
                                    options={reps!}
                                    selectedItemNameLength={40}
                                    handleChange={changeSelectRep} />
                            </Row.Item>
                            <Row.Item lg={3} md={3} sm={3}>
                                <Select
                                    medium
                                    fullWidth
                                    title={t('GENERIC.TEXT.PERIOD',undefined)}
                                    name="selectMonthAfd"
                                    disabled={waitingReport}
                                    value={monthValue.value}
                                    options={listMonths}
                                    handleChange={changeSelectMonth} />
                            </Row.Item>
                            <Row.Item lg={3} md={3} sm={3}>
                                <Select
                                    medium
                                    fullWidth
                                    title={t('GENERIC.TEXT.YEAR',undefined)}
                                    name="selectYearAfd"
                                    disabled={waitingReport}
                                    value={yearValue.value}
                                    options={listYears}
                                    handleChange={changeSelectYear} />
                            </Row.Item>
                            <Row.Item lg={12} md={12} sm={12}>
                                <Container className={styles['btn-generate-file']}>
                                    <Button
                                        success
                                        title={t('EXPORT_FILES.AFD.GENERATE',undefined)}
                                        icon="save"
                                        disabled={repValue === undefined}
                                        loading={waitingReport}
                                        action={() => downloadReportAfd()} />
                                </Container>
                            </Row.Item>
                        </Animate>
                    }
                    {reps !== null && reps?.length === 0 &&
                        <TextInformation icon="search"
                            text={t('EXPORT_FILES.AFD.NO_POINTS',undefined)}
                            subText={t('EXPORT_FILES.AFD.INFO',undefined)} />
                    }
                </Row>
            </>
        );
    };

    const renderItemAfdt = () => {
        return (
            <>
                <Row>
                    {employees !== null && employees?.length === 0 &&
                        <Row.Item lg={12} md={12} sm={12}>
                            <Animate>
                                <AlertTiny
                                    title={t('EXPORT_FILES.AFDT.GENERATE',undefined)}
                                    text={t('EXPORT_FILES.AFDT.INFO',undefined)}
                                    icon="info-circle" />
                            </Animate>
                        </Row.Item>
                    }
                    <Animate>
                        <Row.Item lg={6} md={6} sm={6}>
                            <Select
                                medium
                                fullWidth
                                title={t('GENERIC.TEXT.PERIOD',undefined)}
                                name="selectMonthAfdt"
                                //disabled={waitingReport}
                                value={monthValue.value}
                                options={listMonths}
                                handleChange={changeSelectMonth} />
                        </Row.Item>
                        <Row.Item lg={6} md={6} sm={6}>
                            <Select
                                medium
                                fullWidth
                                title={t('GENERIC.TEXT.YEAR',undefined)}
                                name="selectYearAfdt"
                                disabled={waitingReport}
                                value={yearValue.value}
                                options={listYears}
                                handleChange={changeSelectYear} />
                        </Row.Item>
                    </Animate>
                    {(waitingReport) &&
                        <Row.Item lg={12} md={12} sm={12}>
                            <Animate>
                                <Loading />
                            </Animate>
                        </Row.Item>
                    }
                    {employees !== null && employees?.length === 0 &&
                        <Row.Item lg={12} md={12} sm={12}>
                            <Animate>
                                <TextInformation
                                    icon="search"
                                    text={t('EXPORT_FILES.NO_EMPLOYEE_FOUND',undefined)}
                                    subText={t('EXPORT_FILES.CHANGE_FILTER',undefined)}/>
                            </Animate>
                        </Row.Item>
                    }
                    {(employees !== null && employees?.length > 0 && !waitingReport) &&
                        <Row.Item lg={12} md={12} sm={12}>
                            <Animate>
                                <DualList
                                    titleAvailable={t('CABLE.AREA.NEW.AVAILABLE_EMPLOYEES',undefined)}
                                    titleSelected={t('CABLE.AREA.NEW.SELECTED_EMPLOYEES',undefined)}
                                    options={dualListOptions!}
                                    firstIcon="user"
                                    secondIcon="tools"
                                    thirdIcon="clock"
                                    handleChange={handleChangeDualList} />
                            </Animate>
                        </Row.Item>
                    }
                    {(employees !== null && employees.length > 0) &&
                        <Row.Item lg={12} md={12} sm={12}>
                            <Container className={styles['btn-generate-file']}>
                                <Button
                                    success
                                    title={t('EXPORT_FILES.AFDT.GENERATE',undefined)}
                                    icon="save"
                                    disabled={dualListOptionsSelected?.length === 0}
                                    loading={waitingReport}
                                    action={() => downloadReportAfdt()} />
                            </Container>
                        </Row.Item>
                    }
                </Row>
            </>
        );
    };

    const renderItens = () => {
        return (
            <>
                <Tabs activeTab={0}>
                    <Tabs.Item title="AFDT">
                        {renderItemAfdt()}
                    </Tabs.Item>
                    <Tabs.Item title="AFD">
                        {renderItemAfd()}
                    </Tabs.Item>
                    {/*<Tabs.Item title="ACJEF">

                    </Tabs.Item>*/}
                </Tabs>
            </>
        );
    };

    return (
        <Container className={styles['export-file-rt']}>
            <Row>
                <Row.Item lg={12} md={12} sm={12}>
                    <PageTitle.Small title={t('GENERIC.TEXT.EXPORT_FILES',undefined)} />
				</Row.Item>
			</Row>

			{(exportFileStatus === 'waiting') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Loading text={t('GENERIC.TEXT.LOADING',undefined)}/>
						</Panel>
					</Row.Item>
				</Row>
			}
			{(exportFileStatus === 'success') && 
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<Panel title="" noTitleBorder>
							<Container>
								{renderItens()}
							</Container>
						</Panel>
					</Row.Item>
				</Row>
			}
			{toastAlert.show &&
				<Toast 
					success={!toastAlert.danger}
					danger={toastAlert.danger}
					title={t('GENERIC.TEXT.OPERATION',undefined)}
					icon={toastAlert.danger?'exclamation-circle':'check'}
					positionTopRight
					closeChange={() => setToastAlert({...toastAlert, show: false})}
					message={toastAlert.message} 
					showTime={5000}/>
			}
        </Container>
    );
}

export default ExportFile;