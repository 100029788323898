import React, { useEffect, useState } from "react";
import moment from "moment";

import { Button, ButtonDropdown, Loading, Message, Modal, PageTitle, Panel, Row, Select, Table, TextInformation, Time, DualList, AlertTiny, Tag, AvatarNameValue } from "@optimuminterfaces/revex-react-components/dist/components";
import { convertMinutesToTimeStr, formatLocalDate } from "@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil";
import { convertFloatToTimeStr } from '../../../../utils/DateTimeUtil';

import { fetchAllEmployeeAvailablePointRegistration, fetchPointRegistrations, generateReportEmployeePoint, generateReportEmployeePointLeaf } from "../../../../services/pointRegistrations.services";
import { calculateSelectMonth, calculateSelectYear } from "../../../../utils/SelectUtil";

import { translateStatus } from "../../../../utils/PointIncidentUtil";
import { buildScaleDaily } from "../../../../utils/ScaleUtil";
import { abbreviation } from "@optimuminterfaces/revex-react-components/dist/utils/StringUtils";
import { sortByKeys } from "@optimuminterfaces/revex-react-components/dist/utils/SortUtil";
import { downloadPdf, downloadReportFile } from "@optimuminterfaces/revex-react-components/dist/utils/FileUtil";

import { PointRegistrationCalculate } from "../../../../models/PointRegistrationCalculate";
import { Employee } from "../../../../models/Employee";
import { PointIncident } from "../../../../models/PointIncident";

import Edit from "../Edit/Edit";

import { translation as t } from '@optimuminterfaces/revex-react-components/dist/translation/index';
import { getEnvironment } from "../../../../services/auth.services";
import PointRegistrationEventNew from "../../../organism/PointRegistrationEvent/New/New";
import PointRegistrationEventDelete from "../../../organism/PointRegistrationEvent/Delete/Delete";
import PointRegistrationEventChange from "../../../organism/PointRegistrationEvent/Change/Change";
import PointRegistrationDetailsCalculate from "../../../organism/PointRegistration/Detail/Calculate/Calculate";


import styles from './List.module.scss';

interface PointIncidentModel {
	id: string
}

interface ListProps {
	Container?: any
}

const List = ({ Container = 'div' }: ListProps) => {

	const years = calculateSelectYear();
	const months = calculateSelectMonth(years[3].value);

	let currentMonth = months.filter(m => m.selected == true)[0];

	const [filters, setFilters] = useState<{
		year: any,
		employee: any,
		month: any
	}>()
	const [pointRegistrationsStatus, setPointRegistrationsStatus] = useState<string>('waiting');
	const [pointRegistrations, setPointRegistrations] = useState<PointRegistrationCalculate[]>();
	const [pointRegistrationsLastDayInLastPeriod,setPointRegistrationsLastDayInLastPeriod] = useState<any[]>();
	const [pointRegistrationsFirstDayInNextPeriod,setPointRegistrationsFirstDayInNextPeriod] = useState<any[]>();
	const [total, setTotal] = useState<number[]>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
	const [listYears, setListYears] = useState<any[]>(years);
	const [listMonths, setListMonths] = useState<any[]>(months);
	const [listEmployees, setListEmployees] = useState<any[]>([]);
	const [monthValue, setMonthValue] = useState<any>(currentMonth);
	const [yearValue, setYearValue] = useState<any>(years[3]);
	const [employeeValue, setEmployeeValue] = useState<any>();
	const [isClosedPeriod, setIsClosedPeriod] = useState<boolean>(true);

	const [showModalEditPoint, setShowModalEditPoint] = useState<boolean>(false);
	const [showModalNewPointRegistrationEvent, setShowModalNewPointRegistrationEvent] = useState<boolean>(false);
	const [showModalDeletePointRegistrationEvent, setShowModalDeletePointRegistrationEvent] = useState<boolean>(false);
	const [showModalPointRegistrationEventChange, setShowModalPointRegistrationEventChange] = useState<boolean>(false);
	const [showModalPointRegistrationDetailsCalculate, setShowModalPointRegistrationDetailsCalculate] = useState<boolean>(false);
	const [showModalPointRegistrationDetailsCalculateAllDates, setShowModalPointRegistrationDetailsCalculateAllDates] = useState<boolean>(false);

	const [loadingButtonDeletePointRegistrationEvent, setLoadingButtonDeletePointRegistrationEvent] = useState<boolean>(false);

	const [pointRegistrationEventDelete, setPointRegistrationEventDelete] = useState<any>();

	const [pointRegistrationDetails, setPointRegistrationDetails] = useState<PointRegistrationCalculate>();
	const [pointRegistrationEvent, setPointRegistrationEvent] = useState<PointRegistrationCalculate>();
	const [employees, setEmployees] = useState<Employee[]>();

	const [buttonLoadingReportLeaf, setButtonLoadingReportLeaf] = useState(false);
	const [buttonLoadingReportLeafEmployee, setButtonLoadingReportLeafEmployee] = useState(false);
	const [buttonLoadingReportEmployee, setButtonLoadingReportEmployee] = useState(false);

	const[databasePreviusAndCurrent, setDatabasePreviusAndCurrent,] = useState([0,0]);

	const [modalReportEmployee, setModalReportEmployee] = useState<{ show: boolean, loading: boolean, options: any[], selectedOptions: any[] }>({ show: false, loading: false, options: [], selectedOptions: [] });

	useEffect(() => {
		if(yearValue !== undefined){
			let months = calculateSelectMonth(yearValue.value);
			setListMonths(months);

		}
	},[yearValue]);

	useEffect(() => {
		if(listMonths !== undefined){
			changeSelectMonth(monthValue.key);
		}
	},[listMonths]);


	useEffect(() => {

		const buildSelectOptions = (employees: Employee[]) => {
			let options: { key: string; selected: boolean; value: string, occupation: string, demissionDate: string }[] = [];
			employees.forEach(employee => {
				options.push({
					key: employee.id,
					value: `${abbreviation(employee.name, 32)} ${employee.demissionDate ? '(' + t('GENERIC.TEXT.DEMISSION') + ': ' + formatLocalDate(employee.demissionDate, true, 'YYYY-MM-DD', 'DD/MM/YY') + ')' : ''}`,
					selected: false,
					occupation: employee.occupation!.name,
					demissionDate: employee.demissionDate ? formatLocalDate(employee.demissionDate, true, 'YYYY-MM-DD', 'DD/MM/YY') : null
				})
			});

			options.sort(sortByKeys(['value']))
			setListEmployees(options);
		};

		(async () => {
			setPointRegistrationsStatus('opening');

			try {
				const jsonReturned = await fetchAllEmployeeAvailablePointRegistration();
				if (jsonReturned) {
					//setPointRegistrationsStatus('waiting');
					setEmployees(jsonReturned.employees);
					buildSelectOptions(jsonReturned.employees);
					setEmployeeValue({ key: jsonReturned.employees[0].id, value: jsonReturned.employees[0].name, occupation: jsonReturned.employees[0].occupation.name })
					setFilters({ 
							employee: { 
								key: jsonReturned.employees[0].id, 
								value: jsonReturned.employees[0].name, 
								occupation: jsonReturned.employees[0].occupation.name 
							}, 
							month: monthValue, 
							year: yearValue 
					});
				} else {
					setPointRegistrationsStatus('error');
				}
			} catch (error) {
				console.log(error);
			}

		})();
	}, [])

	useEffect(() => {
		loadData();
	}, [filters]);

	const loadData = () => {
		(async () => {
			if (filters) {
				setPointRegistrationsStatus('waiting');

				try {
					const jsonReturned = await fetchPointRegistrations({ employeeId: filters.employee.key, month: filters.month.key, year: filters.year.key });
					if (jsonReturned) {
						setPointRegistrationsStatus('success');
						setPointRegistrations(jsonReturned.pointRegistrations);
						setTotal(jsonReturned.total);
						setIsClosedPeriod(jsonReturned.isClosedPeriod);
						setPointRegistrationsLastDayInLastPeriod(jsonReturned.pointRegistrationsLastDayInLastPeriod)
						setPointRegistrationsFirstDayInNextPeriod(jsonReturned.pointRegistrationsFirstDayInNextPeriod)
						setDatabasePreviusAndCurrent([jsonReturned.previusCycleAccumulatedDatabase,jsonReturned.currentCycleAccumulatedDatabase]);
					} else {
						setPointRegistrationsStatus('error');
					}
				} catch (error) {
					console.log(error);
				}
			}

		})();
	}

	const changeSelectEmployee = (key: string) => {
		let employees = [...listEmployees!];

		for (var i = 0; i < employees.length; i++) {
			if (employees[i].key === key) {
				employees[i].selected = true;
				setEmployeeValue(employees[i]);
				//setFilters({...filters, month: months[i].key})
			} else {
				employees[i].selected = false;
			}
		}

		setListEmployees(employees);
	};

	const changeSelectMonth = (key: string) => {
		let months = [...listMonths];

		for (var i = 0; i < months.length; i++) {
			if (months[i].key === key) {
				months[i].selected = true;
				setMonthValue(months[i]);
			} else {
				months[i].selected = false;
			}
		}

		//setListMonths(months);
	};

	const changeSelectYear = (key: string) => {
		let years = [...listYears];

		for (var i = 0; i < years.length; i++) {
			if (years[i].key === key) {
				years[i].selected = true;
				setYearValue(years[i]);
			} else {
				years[i].selected = false;
			}
		}

		setListYears(years);
	};

	const downloadReportLeafEmployee = async () => {
		setButtonLoadingReportLeafEmployee(true);

		let fileName = `${t('CABLE.POINT_REGISTRATION.TEXT.SHEET_REPORT')} - ${employeeValue.value} - ${monthValue.value}-${yearValue.value}.pdf`;

		try {
			const jsonReturned = await generateReportEmployeePointLeaf({ year: yearValue.key, month: monthValue.key, employeeId: employeeValue.key });
			if (jsonReturned) {
				downloadPdf(jsonReturned, fileName);
			}
			setButtonLoadingReportLeafEmployee(false);
		} catch (error) {
			console.log(error);
		}
	};

	const updateFilters = () => {
		setFilters({ employee: employeeValue, month: monthValue, year: yearValue });
	};

	const downloadReportEmployee = async () => {
		setButtonLoadingReportEmployee(true);

		let closedPeriodDescription = isClosedPeriod ? t('CLOSED_PERIODS.TEXT.CLOSED_PERIOD') : t('CLOSED_PERIODS.TEXT.OPEN_PERIOD');
		let fileName = `${t('CABLE.POINT_REGISTRATION.TEXT.TIME_SHEET')} - ${employeeValue.value} - ${monthValue.value}-${yearValue.value} (${closedPeriodDescription}).pdf`;

		try {
			const jsonReturned = await generateReportEmployeePoint({ year: yearValue.key, month: monthValue.key, employeeId: employeeValue.key });
			if (jsonReturned) {
				downloadPdf(jsonReturned, fileName);
			}
			setButtonLoadingReportEmployee(false);
		} catch (error) {
			console.log(error);
		}
	};

	const downloadReportEmployeeZip = async (employeesId: string) => {
		let fileName = `${t('CABLE.POINT_REGISTRATION.TEXT.TIME_SHEET')} - ${monthValue.value}-${yearValue.value}.zip`;

		try {
			const jsonReturned = await generateReportEmployeePoint({
				year: yearValue.key,
				month: monthValue.key,
				employeeId: employeesId
			}, {
				'Accept': 'application/zip'
			});

			console.log('jsonReturned', jsonReturned)

			if (jsonReturned) {
				downloadReportFile(jsonReturned, fileName, 'application/zip');
				setModalReportEmployee({ show: false, loading: false, options: [], selectedOptions: [] });
			} else {
				setModalReportEmployee(modalReportEmployee => ({ ...modalReportEmployee, loading: false }));
			}
		} catch (error) {
			console.log(error);
			setModalReportEmployee(modalReportEmployee => ({ ...modalReportEmployee, loading: false }));
		}
	};

	const downloadReportLeaf = async () => {
		setButtonLoadingReportLeaf(true);

		let fileName = `${t('CABLE.POINT_REGISTRATION.TEXT.SHEET_REPORT')}_${moment().format('YYYY-MM-DD_HH-mm')}.pdf`;

		try {
			const jsonReturned = await generateReportEmployeePointLeaf({ year: yearValue.key, month: monthValue.key });
			if (jsonReturned) {
				downloadPdf(jsonReturned, fileName);
			}
			setButtonLoadingReportLeaf(false);
		} catch (error) {
			console.log(error);
		}
	};

	const translatePointType = (pointType: string) => {
		switch (pointType) {
			case 'Manual':
				return 'M';
			case 'Browser':
				return 'N';
			case 'API':
				return 'API';
			case 'AFD':
				return 'AFD';
			case 'AFDT':
				return 'AFDT';
			case 'Justification':
				return 'J';
			case 'Pré-assinalada':
				return 'P';

			default:
				return 'A';
		}
	};

	const renderTablePointRegistration = () => {
		let pointCalculate = pointRegistrations![0].pointCalculate;
		return (
			<>
				<h3 className={styles['sub-title']}>{t('CABLE.POINT_REGISTRATION.TEXT.TIME_SHEET')} {filters?.employee.value}</h3>
				<Table primary responsible className={styles['point-registration-table']} striped>
					<Table.Header>
						<Table.HeaderItem>
							<Table.HeaderItem.Item colSpan={12}>
								<Row className={styles['summary']}>
									<Row.Item sm={12} md={3} lg={3} >
										<Container>
											<AvatarNameValue 
												text={filters?.employee.value} 
												subText={filters?.employee.occupation} 
												size="m" />
										</Container>
									</Row.Item>
									<Row.Item sm={12} md={3} lg={3}>
										<Container>
											<dl>
												<dt className={styles['summary-title']}> <span>{t('GENERIC.TEXT.PERIOD')}:</span></dt>
												<dd className={styles['summary-value']}>{filters?.month.value}</dd>

												<dt className={styles['summary-title']}> <span>{t('CABLE.POINT_REGISTRATION.TEXT.WORKED_HOURS')}:</span></dt>
												<dd className={styles['summary-value']}>{convertMinutesToTimeStr(total![1])}</dd>

												<dt className={styles['summary-title']}> <span>{t('SREP.EDIT.NIGHT_ADDITIONAL')}:</span></dt>
												<dd className={styles['summary-value']}>{convertFloatToTimeStr(total![4])}</dd>

												<dt className={styles['summary-title']}> <span>{t('CABLE.POINT_REGISTRATION.TEXT.LAST_DATABASE')}:</span></dt>
												<dd className={styles['summary-value']}>{convertMinutesToTimeStr(databasePreviusAndCurrent[0])}</dd>

												<dt className={styles['summary-title']}> <span>{t('CABLE.POINT_REGISTRATION.TEXT.CURRENT_DATABASE')}:</span></dt>
												<dd className={styles['summary-value']}>{convertMinutesToTimeStr(databasePreviusAndCurrent[1])}</dd>
											</dl>
										</Container>
									</Row.Item>
									<Row.Item sm={12} md={3} lg={3}>
										<Container>
											<dl>
												<dt className={styles['summary-title']}> <span>{t('CABLE.POINT_REGISTRATION.TEXT.HOLIDAY')}:</span></dt>
												<dd className={styles['summary-value']}>{convertMinutesToTimeStr(total![5])}</dd>

												<dt className={styles['summary-title']}> <span>{t('SCALE.TEXT.LEGEND.DAY_OFF')}:</span></dt>
												<dd className={styles['summary-value']}>{convertMinutesToTimeStr(total![6])}</dd>

												<dt className={styles['summary-title']}> <span>{t('CABLE.POINT_REGISTRATION.TEXT.LATE')}:</span></dt>
												<dd className={styles['summary-value']}><Tag danger title={convertMinutesToTimeStr(total![2])} /></dd>
												
											</dl>
										</Container>
									</Row.Item>
									<Row.Item sm={12} md={3} lg={3}>
										<Container>
											<dl>
												<dt className={styles['summary-title']}> <span>{t('CABLE.POINT_REGISTRATION.TEXT.ABSENCE')}:</span></dt>
												<dd className={styles['summary-value']}>{convertMinutesToTimeStr(total![7])}</dd>

												<dt className={styles['summary-title']}> <span>{t('CABLE.POINT_REGISTRATION.TEXT.LOSS_OF_DSO')}:</span></dt>
												<dd className={styles['summary-value']}>{total![8]}</dd>

												<dt className={styles['summary-title']}> <span>{pointRegistrations![0].pointCalculate['SYSTEM_OVERTIME']['name']}:</span></dt>
												<dd className={styles['summary-value']}><Tag success title={convertMinutesToTimeStr(total![total.length-1])} /></dd>

												<dt className={styles['summary-title']}> <span>{t('CABLE.POINT_REGISTRATION.TEXT.AUTHORIZED_ABSENCE')}:</span></dt>
												<dd className={styles['summary-value']}>{total![10]}</dd>
											</dl>
										</Container>
									</Row.Item>
								</Row>
								
							</Table.HeaderItem.Item>
						</Table.HeaderItem>
						<Table.HeaderItem>
							<Table.HeaderItem.Item>{t('GENERIC.TEXT.DATE')}</Table.HeaderItem.Item>
							<Table.HeaderItem.Item>{t('EMPLOYEE.TEXT.CURRENT_SCALE')}</Table.HeaderItem.Item>
							<Table.HeaderItem.Item>{t('CABLE.POINT_REGISTRATION.TEXT.RECORDS')}</Table.HeaderItem.Item>
							<Table.HeaderItem.Item>{t('CABLE.POINT_REGISTRATION.TEXT.REASON')}</Table.HeaderItem.Item>
							<Table.HeaderItem.Item>{t('CABLE.POINT_REGISTRATION.TEXT.SITUATION')}</Table.HeaderItem.Item>
							<Table.HeaderItem.Item>{t('CABLE.POINT_REGISTRATION.TEXT.OBSERVATION')}</Table.HeaderItem.Item>
							{/* <Table.HeaderItem.Item>{t('CABLE.POINT_REGISTRATION.TEXT.TIME_SHEET')}</Table.HeaderItem.Item> */}

							<Table.HeaderItem.Item>{pointCalculate['SYSTEM_JOURNEY']['name']}</Table.HeaderItem.Item>
							<Table.HeaderItem.Item>{pointCalculate['SYSTEM_WORKING_HOURS']['name']}</Table.HeaderItem.Item>
							<Table.HeaderItem.Item>{pointCalculate['SYSTEM_LATE_WORK']['name']}</Table.HeaderItem.Item>
							<Table.HeaderItem.Item>{pointCalculate['SYSTEM_LATE']['name']}</Table.HeaderItem.Item>
							<Table.HeaderItem.Item>{pointCalculate['SYSTEM_OVERTIME']['name']}</Table.HeaderItem.Item>

							<Table.HeaderItem.Item>{t('GENERIC.TEXT.OPTIONS')}</Table.HeaderItem.Item>
						</Table.HeaderItem>
					</Table.Header>
					<Table.Body>
						{pointRegistrations?.map((point, index) => {
							return (
								<Table.BodyItem key={index + point.datePoint}>
									<Table.BodyItem.Item className={styles['date']}>

										<div>{formatLocalDate(point.datePoint, true, 'YYYY-MM-DD', 'DD/MM/YY')}</div>
										<div>{point.datePointWeekDay.toUpperCase()}</div>
										{isClosedPeriod &&
											// <FontAwesomeIcon icon="lock"></FontAwesomeIcon>
											<Tag success title={t('CLOSED_PERIODS.TEXT.CLOSED_PERIOD')} />
										}

										{!isClosedPeriod &&
											<Tag warn title={t('CLOSED_PERIODS.TEXT.OPEN_PERIOD')} />
										}
									</Table.BodyItem.Item>
									<Table.BodyItem.Item>
										{renderDailyScale(point.scales.times, point.scales.absenceTimes, point.scales.isWindowLunch, point.scales.minutesLunch, point.scales.isAbsence, point.scales.isCompensation)}
									</Table.BodyItem.Item>
									<Table.BodyItem.Item>
										{renderPoint(point.points, point.datePoint)}
									</Table.BodyItem.Item>
									<Table.BodyItem.Item>
										{renderJustify(point.pointIncidents?.filter(e => e.pointIncidentType !== 'AREA')[0]?.resolutionDailyHours?.split('-'))}
									</Table.BodyItem.Item>
									<Table.BodyItem.Item>
										{renderStatus(point.pointIncidents?.filter(e => e.pointIncidentType !== 'AREA')[0])}
									</Table.BodyItem.Item>
									<Table.BodyItem.Item>
										{renderPointDeleted(point.points)}
										{renderPointRegistrationEvents(point.pointRegistrationEvents)}
									</Table.BodyItem.Item>

									{/* <Table.BodyItem.Item>
									{renderPointMirror(point, true)}
								</Table.BodyItem.Item> */}
									<Table.BodyItem.Item className={styles['journey']}>
										{point.pointCalculate['SYSTEM_JOURNEY']['value'] !== 0 ?
											convertMinutesToTimeStr(point.pointCalculate['SYSTEM_JOURNEY']['value']) : '-'}
									</Table.BodyItem.Item>
									<Table.BodyItem.Item>
										{point.pointCalculate['SYSTEM_WORKING_HOURS']['value'] !== 0 ?
											convertMinutesToTimeStr(point.pointCalculate['SYSTEM_WORKING_HOURS']['value']) : '-'}
									</Table.BodyItem.Item>
									<Table.BodyItem.Item className={styles['late-work']}>
										{point.pointCalculate['SYSTEM_LATE_WORK']['value'] !== 0?
											convertMinutesToTimeStr(point.pointCalculate['SYSTEM_LATE_WORK']['value']) : '-'}
									</Table.BodyItem.Item>
									<Table.BodyItem.Item className={styles['late']}>
										{point.pointCalculate['SYSTEM_LATE']['value'] !== 0?
											convertMinutesToTimeStr(point.pointCalculate['SYSTEM_LATE']['value']) : '-'}
									</Table.BodyItem.Item>
									<Table.BodyItem.Item className={styles['overtime']}>
										{point.pointCalculate['SYSTEM_OVERTIME']['value'] !== 0 ?
											convertMinutesToTimeStr(point.pointCalculate['SYSTEM_OVERTIME']['value']) : '-'}
									</Table.BodyItem.Item>

									<Table.BodyItem.Item className={styles['point-actions']}>
										{renderPointOptions(point)}
									</Table.BodyItem.Item>
								</Table.BodyItem>
							)
						})}

					</Table.Body>
				</Table>
			</>
		);
	};

	const renderPointOptions = (point: any) => {

		return (
			<ButtonDropdown title={t('GENERIC.TEXT.OPTIONS')} outlined primary>
				<ButtonDropdown.Session >
					<ButtonDropdown.Session.Item title={`${t('CABLE.POINT_REGISTRATION.TEXT.ADD_POINT_REGISTRATION_EVENT')}`}
						action={() => { setPointRegistrationEvent(point); setShowModalNewPointRegistrationEvent(true) }} />
					<ButtonDropdown.Session.Item title={`${t('GENERIC.TEXT.DETAILS')}`}
						action={() => {
							setPointRegistrationDetails(point);
							setShowModalPointRegistrationDetailsCalculate(true);
						}} />
				</ButtonDropdown.Session>

			</ButtonDropdown>
		);
	}

	const renderJustify = (points: any[]) => {
		return (
			<Container className={styles['point-justify-container']}>
				{points?.map(point => {
					return <Time key={point} time={point} success />
				})}
			</Container>
		);
	};

	const renderStatus = (pointIncident: PointIncident) => {
		return (
			<Container className={styles['point-justify-container']}>
				{pointIncident &&
					<Message message={translateStatus(pointIncident.pointIncidentStatus)}
						danger={pointIncident.pointIncidentStatus === 'DISAPPROVED'}
						primary={pointIncident.pointIncidentStatus === 'OPENED' || pointIncident.pointIncidentStatus === 'WAITING_FOR_REVIEW'}
						success={pointIncident.pointIncidentStatus === 'SOLVED'}
						warning={pointIncident.pointIncidentStatus === 'WAITING'}
						icon={(pointIncident.pointIncidentStatus === 'OPENED' || pointIncident.pointIncidentStatus === 'WAITING_FOR_REVIEW') ? 'signature' : pointIncident.pointIncidentStatus === 'SOLVED' ? 'check' : pointIncident.pointIncidentStatus === 'WAITING' ? 'clock' : ''} />
				}
			</Container>
		);
	};

	const renderDailyScale = (scales: string[], absences: string[], isWindowLunch: boolean, minutesLunch: number, isAbsence: boolean, isCompensation: boolean) => {
		if (isAbsence || isCompensation) {
			return <Container><Message message={isAbsence ? t('CABLE.POINT_REGISTRATION.TEXT.EMP_ABSENCE') : t('GENERIC.TEXT.COMPENSATION')} warning /> </Container>
		} else {
			if (scales.length === 0) {
				return <Container><Message message={t('SCALE.TEXT.LEGEND.DAY_OFF')} danger /> </Container>
			} else if (scales.length === 1 && scales[0] === '?') {
				return <Container><Message message={t('SCALE.TEXT.LEGEND.NO_SCALE')} /> </Container>
			} else {
				let scaleDaily: { isAbsence: boolean, hour: string }[] = buildScaleDaily(scales, absences);

				return scaleDaily.map((scale, index) => {
					return <Container key={index}>
						{index == 1 && isWindowLunch === true && <span className={styles['point-window-title']}>{t('CABLE.POINT_REGISTRATION.TEXT.BREAK')}</span>}
						<Time primary={!scale.isAbsence} warning={scale.isAbsence} time={scale.hour.substring(0, 5)} className={styles[index === 1 || index === 2 ? isWindowLunch ? 'point-window' : 'point' : 'point']} />
						{index == 1 && isWindowLunch === true && <Time className={`${styles['point-window']} ${styles['point-window-duration']}`} time={convertMinutesToTimeStr(minutesLunch)} />}
						{index == 2 && isWindowLunch === true && <span className={styles['point-window-title']}>{t('CABLE.POINT_REGISTRATION.TEXT.BREAK')}</span>}
					</Container>
				});
			}
		}
	};

	const renderPoint = (points: any[], pointDate?: any) => {
		return (
			<Container className={styles['point-realized-container']}>
				{points?.filter(point => point.isDeleted === false).map(point => {
					return <Time key={point.id} time={`${point.time.substring(0, 5)} - ${translatePointType(point.pointType.name)}`} warning />
				})}
			</Container>
		);
	};

	const renderPointDeleted = (points: any[]) => {
		let pointsDeleted = points?.filter(point => point.isDeleted === true);
		return (
			<Container className={styles['point-deleted-container']}>
				{pointsDeleted.length > 0 && <p>Pontos Deletados</p>}
				{pointsDeleted?.map(point => {
					return <Time key={'deleted' + point.id} time={`${point.time.substring(0, 5)} - D`} danger />
				})}
			</Container>
		);
	};

	const renderPointRegistrationEvents = (pointRegistrationEvents: any[]) => {
		return (
			<Container className={styles['point-registrion-events-container']}>
				{pointRegistrationEvents.length > 0 && <p>Eventos Atuais</p>}
				{pointRegistrationEvents?.map(pointRegistrationEvent => {
					return <Container className={styles['point-registrion-events-item']} >
						<Container className={styles['point-registrion-events-item-1']}>
							<Time
								key={'event' + pointRegistrationEvent.id}
								time={`${convertMinutesToTimeStr(pointRegistrationEvent.pointRegistrationEventValue)} 
											- ${pointRegistrationEvent?.pointRegistrationEventType?.code}`}
								primary />
						</Container>
						<Container className={styles['point-registrion-events-item-2']}>
							<Button
								xxs
								danger
								icon={'trash'}
								action={() => {
									setPointRegistrationEventDelete(pointRegistrationEvent);
									setShowModalDeletePointRegistrationEvent(true);
								}}
								outlined />
						</Container>
					</Container>
				})}
			</Container>
		);
	};

	const renderModalDeletePointRegistrationEvent = () => {
		const internalHandleChanges = ({ name, value }: { name: string, value: any }) => {
			switch (name) {

				case 'PointRegistrationEventDelete.cancel':
					{

					}
					break;
				case 'PointRegistrationEventDelete.delete':
					{
						loadData();
					}
					break;

			}

			setShowModalDeletePointRegistrationEvent(false);
			setPointRegistrationEventDelete(undefined)
		}
		return (
			<Modal sm
				className={styles['modal-confirm-delete-point-registration-event']}
				title={t('GENERIC.TEXT.CONFIRM') + ' ' + employeeValue.value}
				handleClose={() => setShowModalDeletePointRegistrationEvent(false)}>
				<PointRegistrationEventDelete
					employeeId={employeeValue.key}
					employeeName={employeeValue.value}
					employeeOccupation={employeeValue.occupation}
					handleChanges={(event: { name: string, value: any }) => internalHandleChanges(event)}
					pointRegistrationEvent={pointRegistrationEventDelete}
				/>
			</Modal>
		);
	}


	const renderModalPointRegistrationEventChange = () => {
		const internalHandleChanges = ({ name, value }: { name: string, value: any }) => {
			switch (name) {

				case 'PointRegistrationEventChange.cancel':
					{

					}
					break;
				case 'PointRegistrationEventChange.change':
					{
						loadData();
					}
					break;

			}
			setShowModalPointRegistrationEventChange(false);
		}
		console.log(monthValue, yearValue)
		return (
			<Modal xs
				className={styles['modal-point-registration-event-change']}
				title={employeeValue.value}
				handleClose={() => setShowModalPointRegistrationEventChange(false)}>
				<PointRegistrationEventChange
					employeeId={employeeValue.key}
					employeeName={employeeValue.value}
					employeeOccupation={employeeValue.occupation}
					startDate={yearValue.value+'-'+monthValue.startDay}
					endDate={yearValue.value+'-'+monthValue.endDay}
					handleChanges={(event: { name: string, value: any }) => internalHandleChanges(event)}
				/>
			</Modal>
		);
	}


	const renderModalPointRegistrationDetailsCalculateAllDates = (pointRegistrationsCalculate: PointRegistrationCalculate[], handleClose: any) => {
		const internalHandleChanges = ({ name, value }: { name: string, value: any }) => {

			setShowModalPointRegistrationDetailsCalculateAllDates(false);
		}
		return (
			<Modal lg
				className={styles['modal-point-registration-detail-all-dates']}
				title={t('GENERIC.TEXT.DETAILS') + ' ' + employeeValue.value}
				showClose
				handleClose={handleClose}>
				<PointRegistrationDetailsCalculate
					pointRegistrationCalculate={pointRegistrationsCalculate!}
					databasePreviusAndCurrent={databasePreviusAndCurrent}
				/>
			</Modal>
		);
	}

	const renderModalReportEmployee = () => {

		return (
			<Modal title={`${t('CABLE.POINT_REGISTRATION.TEXT.TIME_SHEET')} - ${monthValue?.value} / ${yearValue.value}`}
				lg
				showClose
				handleClose={() => setModalReportEmployee({ show: false, loading: false, options: [], selectedOptions: [] })}>
				<Container>
					{(modalReportEmployee?.loading !== true) &&
						<>
							<AlertTiny
								title={t('GENERIC.TEXT.ATTENTION')}
								text={t('CABLE.POINT_REGISTRATION.TEXT.REPORT_MAX_EMPLOYEES', undefined, 75)}
								icon="info-circle" />

							<DualList
								titleAvailable={t('COMPONENTS.DUAL_LIST.AVAILABLES', undefined, t('GENERIC.TEXT.EMPLOYEES'))}
								titleSelected={t('COMPONENTS.DUAL_LIST.SELECTED', undefined, t('GENERIC.TEXT.EMPLOYEES'))}
								firstIcon='user'
								secondIcon='tools'
								thirdIcon='users'
								color='#0863D3'
								selectedColor='#3180E1'
								disabled={modalReportEmployee.loading}
								options={modalReportEmployee.options}
								handleChange={values => {
									const selectedOptions = values?.filter(({ selected }: { selected: boolean }) => selected);
									setModalReportEmployee(modalReportEmployee => ({ ...modalReportEmployee, selectedOptions }));
								}} />
						</>
						||
						<Loading text={t('GENERIC.TEXT.LOADING')} />
					}
				</Container>

				<Container className={styles['modal-footer-rt']}>
					<Button title={t('GENERIC.BUTTON.DOWNLOAD.TEXT')}
						primary
						disabled={modalReportEmployee?.selectedOptions?.length <= 1 || modalReportEmployee?.selectedOptions?.length > 75}
						loading={modalReportEmployee.loading}
						action={(e) => {
							e.preventDefault();
							setModalReportEmployee(modalReportEmployee => ({ ...modalReportEmployee, loading: true }));

							const employeesId = modalReportEmployee.selectedOptions.map(({ key }) => (key)).join(';;');
							downloadReportEmployeeZip(employeesId);
						}} />
				</Container>
			</Modal>
		);
	};

	return (
		<Container className={styles['point-registration-list']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<PageTitle.Small title={t('CABLE.POINT_REGISTRATION.TEXT.ATTENDANCE_CONTROL')} />
				</Row.Item>
			</Row>
			{pointRegistrationsStatus === 'opening' &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Loading text={t('GENERIC.TEXT.LOADING')} />
						</Panel>
					</Row.Item>
				</Row>
			}
			{pointRegistrationsStatus !== 'opening' &&

				<>
					<Row>
						<Row.Item sm={12} md={12} lg={12}>
							<Panel title={t('GENERIC.TEXT.FILTERS')} className={styles['point-registration-filters']} noTitleBorder>
								<Row>
									<Row.Item sm={4} md={4} lg={4}>
										<Select
											title={t('GENERIC.TEXT.EMPLOYEE')}
											value={employeeValue?.value}
											options={listEmployees}
											name='employee'
											handleChange={changeSelectEmployee}
											fullWidth />
									</Row.Item>
									<Row.Item sm={3} md={3} lg={3}>
										<Select
											title={t('GENERIC.TEXT.PERIOD')}
											value={monthValue.value}
											options={listMonths}
											name='period'
											handleChange={changeSelectMonth}
											fullWidth />
									</Row.Item>
									<Row.Item sm={3} md={3} lg={3}>
										<Select
											title={t('GENERIC.TEXT.YEAR')}
											value={yearValue.value}
											options={listYears}
											name='year'
											handleChange={changeSelectYear}
											fullWidth />
									</Row.Item>
									<Row.Item sm={2} md={2} lg={2}>
										<Button
											title={t('GENERIC.BUTTON.SEARCH.TEXT')}
											action={() => {
												updateFilters()
											}}
											primary />
									</Row.Item>
								</Row>
							</Panel>
						</Row.Item>
					</Row>

					{(pointRegistrationsStatus === 'waiting') &&
						<Row>
							<Row.Item lg={12} md={12} sm={12}>
								<Panel>
									<Loading text={t('CABLE.POINT_REGISTRATION.TEXT.SEEKING_INFORMATION')} />
								</Panel>
							</Row.Item>
						</Row>
					}
					{(pointRegistrationsStatus === 'error') &&
						<Row>
							<Row.Item lg={12} md={12} sm={12}>
								<Panel>
									<TextInformation
										icon="exclamation-circle"
										text={t('GENERIC.TEXT.UNABLE_RETURN')} />
								</Panel>
							</Row.Item>
						</Row>
					}
					{pointRegistrations && pointRegistrationsStatus === 'success' &&
						<Row>
							<Row.Item sm={12} md={12} lg={12}>
								<Panel title={t('CABLE.POINT_REGISTRATION.TEXT.TIME_RECORDS')} noPadding noTitleBorder>
									<>
										<Row>
											<Row.Item sm={12} md={12} lg={12}>
												<Container className={styles['point-actions']}>
													<Container>
														<ButtonDropdown title={t('GENERIC.TEXT.REPORTS')} outlined primary icon='file'>
															<ButtonDropdown.Session title={t('CABLE.POINT_REGISTRATION.TEXT.PDF_EXPORTATION')} icon='file-pdf'>
																<ButtonDropdown.Session.Item title={`${t('CABLE.POINT_REGISTRATION.TEXT.MANUAL_TIME_SHEET')} (${t('GENERIC.TEXT.SINGLE')})`}
																	subTitle={employeeValue.value}
																	loading={buttonLoadingReportLeafEmployee}
																	action={() => downloadReportLeafEmployee()} />
																<ButtonDropdown.Session.Item title={`${t('CABLE.POINT_REGISTRATION.TEXT.MANUAL_TIME_SHEET')} (${t('GENERIC.TEXT.TEAM')})`}
																	loading={buttonLoadingReportLeaf}
																	subTitle={`${t('GENERIC.TEXT.ALL')} ${t('GENERIC.TEXT.EMPLOYEES')}`}
																	action={() => downloadReportLeaf()} />
																<ButtonDropdown.Session.Item title={t('CABLE.POINT_REGISTRATION.TEXT.TIME_SHEET')}
																	subTitle={employeeValue.value}
																	loading={buttonLoadingReportEmployee}
																	action={() => downloadReportEmployee()} />
																<ButtonDropdown.Session.Item title={t('CABLE.POINT_REGISTRATION.TEXT.TIME_SHEET')}
																	subTitle={`${t('GENERIC.TEXT.ALL')} ${t('GENERIC.TEXT.EMPLOYEES')}`}
																	loading={modalReportEmployee.show}
																	action={() => {
																		const employeesValue = employees || [];
																		const options = employeesValue.map(employee => ({
																			key: employee.id,
																			values: [
																				`${abbreviation(employee.name, 32)} ${employee.demissionDate ? '(' + t('GENERIC.TEXT.DEMISSION') + ': ' + formatLocalDate(employee.demissionDate, true, 'YYYY-MM-DD', 'DD/MM/YY') + ')' : ''}`,
																				employee.occupation?.name
																			],
																			selected: false,
																			demissionDate: employee.demissionDate ? employee.demissionDate : null
																		})).filter(e => {
																			return e.demissionDate === null || (new Date(e.demissionDate) > new Date(yearValue.key + '-' + monthValue.startDay))
																		});


																		setModalReportEmployee(modalReportEmployee => ({ ...modalReportEmployee, show: true, options }));
																	}} />

															</ButtonDropdown.Session>
														</ButtonDropdown>
													</Container>
													<Container>
														<ButtonDropdown title={t('GENERIC.TEXT.OPTIONS')} outlined primary icon='cogs'>
															<ButtonDropdown.Session title={t('GENERIC.TEXT.ACTIONS')} icon='file'>
																<ButtonDropdown.Session.Item
																	icon={isClosedPeriod ? "lock" : null}
																	disabled={isClosedPeriod}
																	title={`${t('CABLE.POINT_REGISTRATION.TEXT.RECORDS_ADJUST')}`}
																	action={() => setShowModalEditPoint(true)}
																/>
																<ButtonDropdown.Session.Item
																	title={`${t('CABLE.POINT_REGISTRATION.TEXT.DETAIL_POINT')}`}
																	action={() => setShowModalPointRegistrationDetailsCalculateAllDates(true)}
																/>
																
															</ButtonDropdown.Session>
															<ButtonDropdown.Session title={t('GENERIC.TEXT.BATCH_ACTIONS')} icon='files-medical'>
																<ButtonDropdown.Session.Item
																	title={`${t('CABLE.POINT_REGISTRATION.TEXT.REPLACE_EVENT')}`}
																	action={() => setShowModalPointRegistrationEventChange(true)}
																/>
																{/* <ButtonDropdown.Session.Item
																	title={`${t('CABLE.POINT_REGISTRATION.TEXT.ADD_POINT_REGISTRATION_EVENT')}`}
																	action={() => setShowModalPointRegistrationDetailsCalculateAllDates(true)}
																/> */}
															</ButtonDropdown.Session>
														</ButtonDropdown>
													</Container>
												</Container>
											</Row.Item>
										</Row>
										<Row>
											<Row.Item sm={12} md={12} lg={12}>
												<Container className={styles['legend']}>
													<strong>{t('CABLE.POINT_REGISTRATION.TEXT.LABEL')}</strong>
													<span><strong>M</strong> - {t('CABLE.POINT_REGISTRATION.TEXT.MANUAL_REGISTRATION')}</span>
													<span><strong>A</strong> - {t('CABLE.POINT_REGISTRATION.TEXT.AUTOMATIC_REGISTRATION')}</span>
													<span><strong>API</strong> - {t('CABLE.POINT_REGISTRATION.TEXT.API_REGISTRATION')}</span>
													<span><strong>AFD</strong> - {t('CABLE.POINT_REGISTRATION.TEXT.REGISTRATION_EXPORT')}</span>
													<span><strong>AFDT</strong> - {t('CABLE.POINT_REGISTRATION.TEXT.REGISTRATION_IMPORT')}</span>
													<span><strong>N</strong> - {t('CABLE.POINT_REGISTRATION.TEXT.BROWSER_REGISTRATION')}</span>
													<span><strong>J</strong> - {t('CABLE.POINT_REGISTRATION.TEXT.REGISTRATION_JUSTIFICATION')}</span>
													<span><strong>D</strong> - {t('CABLE.POINT_REGISTRATION.TEXT.REGISTRATION_REMOVED')}</span>
													<span><strong>P</strong> - {t('CABLE.POINT_REGISTRATION.TEXT.PRE_FILLED_POINT')}</span>
												</Container>
											</Row.Item>
										</Row>
										<Row>
											<Row.Item sm={12} md={12} lg={12}>
												{renderTablePointRegistration()}
											</Row.Item>
										</Row>
									</>
								</Panel>
							</Row.Item>
						</Row>
					}

					{showModalEditPoint &&
						<Modal bg
							className={styles['modal-edit-point']}
							title={t('CABLE.POINT_REGISTRATION.TEXT.RECORDS_EDITION') + ' ' + employeeValue.value}
							showClose
							handleClose={() => setShowModalEditPoint(false)}>
							<Edit
								employeeId={employeeValue.key}
								month={filters?.month.key}
								year={filters?.year.key}
								pointRegistrations={pointRegistrations!}
								pointRegistrationsFirstDayInNextPeriod={pointRegistrationsFirstDayInNextPeriod!}
								pointRegistrationsLastDayInLastPeriod={pointRegistrationsLastDayInLastPeriod!}
								fixedActions
								handleChange={() => updateFilters()}
								handleClose={() => setShowModalEditPoint(false)}
							/>
						</Modal>
					}

					{showModalNewPointRegistrationEvent &&
						<Modal sm
							title={t('CABLE.POINT_REGISTRATION.TEXT.ADD_POINT_REGISTRATION_EVENT')}
							showClose
							className={styles['modal-point-registration-event-new']}
							handleClose={() => setShowModalNewPointRegistrationEvent(false)}>
							<PointRegistrationEventNew
								handleChanges={(event: any) => {
									console.log(event)
									switch (event.name) {
										case 'PointRegistrationEventNew.cancel':
											setShowModalNewPointRegistrationEvent(false);
											break;
										case 'PointRegistrationEventNew.success':
											setShowModalNewPointRegistrationEvent(false);
											loadData();
											break;

										default:
											break;
									}
								}}
								employeeName={employeeValue.value}
								employeeId={employeeValue.key}
								employeeOccupation={employeeValue.occupation}
								pointRegistrationCalculate={pointRegistrationEvent!} />
						</Modal>
					}

					{showModalDeletePointRegistrationEvent &&
						renderModalDeletePointRegistrationEvent()
					}

					{showModalPointRegistrationDetailsCalculate &&
						renderModalPointRegistrationDetailsCalculateAllDates([pointRegistrationDetails!], 
							() => {
								setShowModalPointRegistrationDetailsCalculate(false);
								setPointRegistrationDetails(undefined);
							}
						)
					}

					{modalReportEmployee.show && renderModalReportEmployee()}

					{showModalPointRegistrationEventChange && renderModalPointRegistrationEventChange()}

					{showModalPointRegistrationDetailsCalculateAllDates &&
						renderModalPointRegistrationDetailsCalculateAllDates(pointRegistrations!, 
							() => setShowModalPointRegistrationDetailsCalculateAllDates(false)
						)
					}
				</>
			}
		</Container>
	);
};

export default List;