import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

import { fetchAllMyPointIncidents } from '../../../../../services/pointIncident.services';
import { fetchAllComments } from '../../../../../services/pointRegistrations.services';

import { calculateSelectMonth, calculateSelectYear } from '../../../../../utils/SelectUtil';
import { formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import { PointComment } from '../../../../../models/PointComment';
import { translateStatus, translateType } from '../../../../../utils/PointIncidentUtil';

import { AvatarName, Button, Loading, Message, Modal, PageTitle, Panel, Row, Select, TextInformation, Time } from '@optimuminterfaces/revex-react-components/dist/components';
import TableListPagination from '../../../../organism/TableListPagination/TableListPagination';
import { PointIncident } from '../../../../../models/PointIncident';
import Justification from '../Justification/Justification';
import NewJustification from '../New/New';

import imageUser from '../../../../../assets/images/avatar.png';

import styles from './List.module.scss';

interface ListProps{
	Container?: any
};

const List = ({Container='div'}: ListProps) => {

	let navigate = useNavigate();

	const years = calculateSelectYear();
	const months = calculateSelectMonth(years[3].value);

	let currentMonth = months.filter(m => m.selected==true)[0];


	const URL_MICRO_FRONT = process.env.REACT_APP_URL;

	const statuses = [
		{key:'',value:t('CABLE.POINT_INCIDENT.ALL'), selected: true},
		{key:'OPENED',value:t('CABLE.POINT_INCIDENT.AWAITING_JUSTIFICATION'), selected: false},
		{key:'WAITING',value:t('CABLE.POINT_INCIDENT.WAITING_APROOVAL'), selected: false},
		{key:'SOLVED_AUTO',value:t('CABLE.POINT_INCIDENT.SOLVED_AUTO'), selected: false},
		{key:'SOLVED',value:t('CABLE.POINT_INCIDENT.SOLVED'), selected: false},
		{key:'DISAPPROVED',value:t('CABLE.POINT_INCIDENT.REFUSED'), selected: false}
	];

	const [incidents, setIncidents] = useState<PointIncident[]>();
	const [comments, setComments] = useState<PointComment[]>();
	const [incidentStatus, setIncidentStatus] = useState<string>();
	const [filterIncidentes, setFilterIncidentes] = useState<PointIncident[]>();
	const [yearValue, setYearValue] = useState<string>(new Date().getFullYear().toString());
	const [monthValue, setMonthValue] = useState<any>(currentMonth);
	const [statusValue, setStatusValue] = useState<any>(statuses[0]);
    const [listYears, setListYears] = useState(years);
    const [listMonths, setListMonths] = useState(months);
	const [listStatuses, setListStatuses] = useState(statuses);
	const [justifyIncident, setJustifyIncident] = useState<PointIncident>();
	const [showModalJustification, setShowModalJustification] = useState(false);
	const [showModalNewJustification, setShowModalNewJustification] = useState<boolean>(false);

	const [filters, setFilters] = useState<{year: string, month: string, status?: string}>({year: yearValue, month: monthValue.key});

	useEffect(() => {
		(async () => {
			setIncidentStatus('waiting');
			try{
				setListMonths(calculateSelectMonth(yearValue));
				changeSelectMonth(monthValue.key, false, calculateSelectMonth(yearValue));
				const jsonReturned =  await fetchAllMyPointIncidents(filters);
				if(jsonReturned){
					setIncidents(jsonReturned.pointIncidents);
					filterList(jsonReturned.pointIncidents, statusValue.key)
					setIncidentStatus('success');
				}else{
					setIncidentStatus('error');
				}
			}catch(error){
				console.log(error);
			}

			try{
				const jsonReturned =  await fetchAllComments();
				if(jsonReturned){
					setComments(jsonReturned.comments);
				}
			}catch(error){
				console.log(error);
			}

		})();
    }, [filters]);

	const handleColumnRender = ( columnValue:any, columnName:string, columnIndex:number, row: PointIncident ) => {
		
		switch(columnName){
			case 'employeeId':
				return <AvatarName size='s'
					color=''
					image={`${URL_MICRO_FRONT}${imageUser}`}
					text={row.employee.name}
					subText={row.employee.occupation?.name} />
			case 'incidentDate':
				return formatLocalDate(columnValue, true, 'YYYY-MM-DD', undefined);
			case 'pointIncidentStatus':
				return renderStatus(columnValue);
			case 'pointIncidentType':
				return (
					<>
						<Message message={translateType(columnValue,row.actualPointsNumber, row.expectedPointsNumber)}
									icon='exclamation-circle'
									warning />
						{row.pointIncidentType !== 'AREA' && 
							<Container style={{ marginTop:'10px'}}>
								<Container><strong>{t('CABLE.POINT_INCIDENT.PLANNED')}:</strong> {row.expectedDailyHours}</Container>
								<Container><strong>{t('CABLE.POINT_INCIDENT.DONE')}:</strong> {row.actualDailyHours}</Container>
								<Container><strong>{t('CABLE.POINT_REGISTRATION.TEXT.REASON')}:</strong> {row.resolutionDailyHours}</Container>
							</Container>
						}
					</>
					)
			case 'actions':
				return (
					<>
						{(row.pointIncidentStatus ==='OPENED' || row.pointIncidentStatus === 'WAITING_FOR_REVIEW') &&
							<Button warn sm
								title={t('CABLE.POINT_INCIDENT.JUSTIFY')}
								className={styles['table-item-action']}
								action={() => { setJustifyIncident(row);  showOrHideModalJustification();}} />
						}
					</>
				)
			default:
				return columnValue;
		}
	};

	const renderStatus = (status: string) => {
		return (
			<Message message={translateStatus(status)}
				danger={status === 'DISAPPROVED'}
				primary={status === 'OPENED' || status === 'WAITING_FOR_REVIEW'}
				success={status === 'SOLVED' || status === 'SOLVED_AUTO'}
				warning={status === 'WAITING'}
				icon={(status === 'OPENED' || status === 'WAITING_FOR_REVIEW') ? 'signature' : (status === 'SOLVED' || status === 'SOLVED_AUTO') ? 'check' : status === 'WAITING' ? 'clock' : ''} />
		);
	};

	const showOrHideModalJustification = () =>{
		setShowModalJustification(!showModalJustification)
	};

	const showOrHideModalNewJustification = () => {
		if (showModalJustification) {
			setFilters({ ...filters });
		}

		setShowModalNewJustification(!showModalNewJustification);
	};

	const changeSelectMonth = (key: string, updateFilters: boolean = true, ms?: any[]) => {
        let months = ms?[...ms]:[...listMonths];

        for (var i = 0; i < months.length; i++) {
            if (months[i].key === key) {
                months[i].selected = true;
                setMonthValue(months[i]);
				updateFilters && setFilters({...filters, month: months[i].key})
            } else {
                months[i].selected = false;
            }
        }

        //setListMonths(months);
    };

	const changeSelectYear = (key: string) => {
        let years = [...listYears];

        for (var i = 0; i < years.length; i++) {
            if (years[i].key === key) {
                years[i].selected = true;
                setYearValue(years[i].key);
				setFilters({...filters, year: years[i].key})
            } else {
                years[i].selected = false;
            }
        }

        setListYears(years);
    };

	const changeSelectStatus = (key: string) => {
        let statuses = [...listStatuses];

        for (var i = 0; i < statuses.length; i++) {
            if (statuses[i].key === key) {
                statuses[i].selected = true;
                setStatusValue(statuses[i]);
				//setFilters({...filters, status: statuses[i].key})
				filterList(incidents!, statuses[i].key);
            } else {
                statuses[i].selected = false;
            }
        }

        setListStatuses(statuses);
    };

	const filterList = (listIncidents: PointIncident[], status: string | null) => {
		let list: PointIncident[] = [...listIncidents!];

		if (status !== null && status !== '' && list!.length > 0) {
            list = list!.filter(e => 
                e.pointIncidentStatus.toUpperCase() === (status.toUpperCase()));
        }

        setFilterIncidentes([...list]);
    };

	const JustificationHandleChange = (event: any) => {
		if(event.name ==='operation success'){
			//Atualiza lista sem necessidade de request
			let inc: PointIncident[] = [...incidents!];
			inc.forEach(i => {
				if(i.id===event.value.id){
					i.pointIncidentStatus='WAITING';
				}
			});
			setIncidents([...inc]);
		}
	};
	
	return (
		<Container className={styles['point-incident-list-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<PageTitle.Small title={t('CABLE.POINT_INCIDENT.MY_INCIDENTS')} />
				</Row.Item>
			</Row>

			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Panel title={t('GENERIC.TEXT.FILTERS')} noTitleBorder>
						<Row>
							<Row.Item lg={3} md={3} sm={6}>
								<Select medium fullWidth name="selectStatus" value={statusValue.value} title={t('CABLE.POINT_REGISTRATION.TEXT.SITUATION')} options={listStatuses} handleChange={changeSelectStatus} />
							</Row.Item>
							<Row.Item lg={3} md={3} sm={6}>
								<Select medium fullWidth name="selectMonth" value={monthValue.value} title={t('GENERIC.TEXT.PERIOD')} options={listMonths} handleChange={changeSelectMonth} />
							</Row.Item>
							<Row.Item lg={3} md={3} sm={6}>
								<Select medium fullWidth name="selectYear" value={yearValue.toString()} title={t('GENERIC.TEXT.YEAR')} options={listYears} handleChange={changeSelectYear} />
							</Row.Item>
						</Row>
					</Panel>
				</Row.Item>
			</Row>

			{(incidentStatus === 'waiting') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Loading text={t('GENERIC.TEXT.LOADING')}/>
						</Panel>
					</Row.Item>
				</Row>
			}

			{(incidentStatus === 'error') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<TextInformation 
								icon='exclamation-circle'
								text={t('GENERIC.TEXT.UNABLE_RETURN')} />
						</Panel>
					</Row.Item>
				</Row>
			}

			{incidents &&  incidentStatus === 'success' && incidents.length === 0 &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel className={styles['panel-list-justifications']} noPadding noTitleBorder /*actions={[
							{
								title: `${t('GENERIC.TEXT.REGISTER')} ${t('GENERIC.TEXT.INCIDENT')}`,
								icon: 'plus',
								primary: true,
								action: () => { showOrHideModalNewJustification() }
							}
						]}*/>
							<TextInformation 
								icon="search"
								text={t('CABLE.POINT_INCIDENT.NO_INCIDENTS_FOUND')} />
						</Panel>
					</Row.Item>
				</Row>
			}

			{(incidents && incidentStatus === 'success') && incidents.length > 0 && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel className={styles['panel-list-justifications']} noPadding noTitleBorder /*actions={[
							{
								title: `${t('GENERIC.TEXT.REGISTER')} ${t('GENERIC.TEXT.INCIDENT')}`,
								icon: 'plus',
								primary: true,
								action: () => { showOrHideModalNewJustification() }
							}
						]}*/>
							<TableListPagination list={filterIncidentes}
								headers={[t('GENERIC.TEXT.EMPLOYEE'), t('CABLE.POINT_INCIDENT.INCIDENT_DATE'), t('CABLE.POINT_REGISTRATION.TEXT.SITUATION'), t('CABLE.POINT_INCIDENT.PROBLEM'), t('GENERIC.TEXT.OPTIONS')]}
								columns={['employeeId', 'incidentDate', 'pointIncidentStatus', 'pointIncidentType', 'actions']}
								handleColumnRender={handleColumnRender} />
						</Panel>
					</Row.Item>
				</Row>
			}

			{ showModalJustification &&
                <Modal title={t('CABLE.POINT_REGISTRATION.TEXT.REASON')} showClose lg handleClose={() => { showOrHideModalJustification() }}>
                    <Container className={styles['modal-justify']}>
						<Justification justifyIncident={justifyIncident!}
							pointComments={comments}
							handleChange={JustificationHandleChange}
							handleClose={() => showOrHideModalJustification()} />
                    </Container>                   
                </Modal>
            }

			{ showModalNewJustification &&
				<Modal title={`${t('GENERIC.TEXT.REGISTER')} ${t('GENERIC.TEXT.INCIDENT')}`} showClose lg handleClose={() => showOrHideModalNewJustification()}>
					<Container className={styles['modal-justify']}>
						<NewJustification 
							pointComments={comments}
							handleClose={() => showOrHideModalNewJustification()} />
					</Container>
				</Modal>
			}

		</Container>
	)
}

export default List;