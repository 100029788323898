export const convertFloatToTimeStr = (timeInMinutes: number) => {
	try{
		const hours = Math.floor(Math.abs(timeInMinutes)/60);
		const minutes = Math.trunc(Math.abs(timeInMinutes))%60;
		const secounds = Math.trunc(Math.abs(timeInMinutes)%1*100)%60;
		return `${timeInMinutes < 0 ? '-' : ''}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secounds).padStart(2, '0')}`;
	}catch(e){
		return '--:--:--';
	}
};

export const minutesBetween = (start: Date, end: Date, type: string='minutes') => {
	let operation = 0;
	switch (type) {
		case 'second':
			operation = (1000);
			break;
		case 'minutes':
			operation = (1000 * 60);
			break;
		case 'hours':
			operation = (1000 * 60 * 60);
			break;
		default:
			operation = (1000 * 60);
			break;
	}
	const diff = (end.getTime() - start.getTime()) / (operation);
	return diff;
}

export const plusDays = (date: Date, days: number) => {

	date.setDate(date.getDate() - days);

	return date;

}