import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { AvatarName, ButtonDropdown, Button, Input, Loading, Modal, PageTitle, Panel, Row, Select, TextInformation } from '@optimuminterfaces/revex-react-components/dist/components/index';
import { downloadCsv } from "@optimuminterfaces/revex-react-components/dist/utils/FileUtil";
import { copyObjectJSON } from "@optimuminterfaces/revex-react-components/dist/utils/DeveloperUtil";

import imageUser from '../../../../assets/images/avatar.png';

import { downloadCsvHourBankBalances, fetchHourBankBalances } from '../../../../services/hourBankBalances.services';

import { calculateSelectYear } from '../../../../utils/SelectUtil';

import { translation as t } from '@optimuminterfaces/revex-react-components/dist/translation/index';
import TableListPagination from '../../../organism/TableListPagination/TableListPagination';
import { calculateCycleOfEnvironment } from '../../../../utils/DeveloperUtil';

import { convertMinutesToTimeStr } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import { useNavigate, createSearchParams } from 'react-router-dom';

import {default as HourBankBalanceEdit} from '../../../organism/HourBankBalance/Edit/Edit';
import { HourBankBalance } from '../../../../models/HourBankBalance';
import { ComponentEvent } from '../../../../models/ComponentEvent';

import styles from './List.module.scss';

interface ListProps {
	Container?: any
}

const List = ({ Container = 'div' }: ListProps) => {
	const URL_MICRO_FRONT = process.env.REACT_APP_URL;
	const years = calculateSelectYear();
	const navigate = useNavigate();

	const [hourBankBalances, setHourBankBalances] = useState<any[]>();
	const [editHourBankBalance, setEditHourBankBalance] = useState<HourBankBalance>();
	const [showModalEditHourBankBalance, setShowModalEditHourBankBalance] = useState<boolean>(false);
	const [hourBankBalanceStatus, setHourBankBalanceStatus] = useState('waiting');
	const [filterHourBankBalances, setFilterHourBankBalances] = useState<any[]>();
	const [listYears, setListYears] = useState<any[]>(years);
	const [yearValue, setYearValue] = useState(years[3]);
	const [employeeNameValue, setEmployeeNameValue] = useState<string | null>(null);
	const [occupationValue, setOccupationValue] = useState<string | null>(null);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [months, setMonths] = useState(calculateCycleOfEnvironment(years[3].value));

	useEffect(() => {
		(async () => {
			setMonths(calculateCycleOfEnvironment(yearValue.value));
			loadData()

		})();
	}, [yearValue]);

	const loadData = async () => {
		setHourBankBalanceStatus('waiting');
		try {
			const jsonReturned = await fetchHourBankBalances({ 'year': yearValue.key });
			if (jsonReturned) {
				setHourBankBalances(copyObjectJSON(jsonReturned.hourBankBalances));
				setHourBankBalanceStatus('success');
				filterList(employeeNameValue, occupationValue, jsonReturned.hourBankBalances);
			} else {
				setHourBankBalances([]);
				setFilterHourBankBalances([]);
				setHourBankBalanceStatus('error');
			}
		} catch (error) {
			console.log(error);
		}
	};

	const filterList = (employeeName: string | null, occupation: string | null, list: any[] | null) => {
		if (list === null) {
			list = copyObjectJSON(hourBankBalances);
		}

		if (occupation !== null) {
			list = list!.filter(e =>
				e.employeeOccupation?.toUpperCase().includes(occupation.toUpperCase()));
		}

		if (employeeName !== null && list!.length > 0) {
			list = list!.filter(e =>
				e.employeeName?.toUpperCase().includes(employeeName.toUpperCase()));
		}

		setFilterHourBankBalances(copyObjectJSON(list));
	};

	const employeeNameHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.value.length >= 1) {
			setEmployeeNameValue(event.target.value);
			filterList(event.target.value, occupationValue, null);
		} else {
			setEmployeeNameValue(null);
			filterList(null, occupationValue, null);
		}
	};

	const occupationHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.value.length >= 1) {
			setOccupationValue(event.target.value);
			filterList(employeeNameValue, event.target.value, null);
		} else {
			setOccupationValue(null);
			filterList(employeeNameValue, null, null);
		}
	};

	const changeSelectYear = (key: string, notFind: null | boolean) => {
		let years = [...listYears];

		for (var i = 0; i < years.length; i++) {
			if (years[i].key === key) {
				years[i].selected = true;
				setYearValue(years[i]);
			} else {
				years[i].selected = false;
			}
		}

		setListYears([...years]);
	};

	const downloadReport = async () => {
		setButtonLoading(true);

		let fileName = 'RelatórioBancoDeHoras_' + moment().format('YYYY-MM-DD_HH-mm') + '.csv';

		try {
			const jsonReturned = await downloadCsvHourBankBalances({ year: yearValue.key, employeeName: employeeNameValue, occupationName: occupationValue });
			if (jsonReturned) {
				downloadCsv(jsonReturned, fileName);
			}
			setButtonLoading(false);
		} catch (error) {
			console.log(error);
		}
	};

	const redirectToEmployee = (employeeId: string) => {
		navigate(`/employees/show/${employeeId}`);
    };

	const redirectToReportsHourBalances = () => {
		navigate({
			pathname: '/srepsMF/reports',
			search: createSearchParams({
				type: 'hourBankBalances'
			}).toString()
		})
    };

	const redirectToReportsHourBalanceDivergences = () => {
		navigate({
			pathname: '/srepsMF/reports',
			search: createSearchParams({
				type: 'hourBankBalanceDivergences'
			}).toString()
		})
    };

	const handleColumnRender = (columnValue: any, columnName: string, columnIndex: number, row: any) => {
		switch (columnName) {
			case 'employeeName':
				return <Container onClick={() => {redirectToEmployee(row.employeeId)}}>
				<AvatarName size='s'
					color=''
					image={`${URL_MICRO_FRONT}${imageUser}`}
					text={row.employeeName}
					subText={row.employeeOccupation} />
				</Container>
			case 'january':
			case 'february':
			case 'march':
			case 'april':
			case 'may':
			case 'june':
			case 'july':
			case 'august':
			case 'september':
			case 'october':
			case 'november':
			case 'december':

				return convertMinutesToTimeStr(columnValue);
			case 'options':
				return <Button 
							xs 
							title={t('GENERIC.TEXT.EDIT')} 
							icon='pen'
							action={() => {
								setEditHourBankBalance(row);
								setShowModalEditHourBankBalance(true);
							}}/>

			default:
				return columnValue;
		}
	};

	const internalHandleChanges=(event: ComponentEvent) => {
		switch (event.name) {
			case 'HourBankBalanceEditCancel':		
				setShowModalEditHourBankBalance(false);
				setEditHourBankBalance(undefined);
				break;
			case 'HourBankBalanceEditSave':
				setShowModalEditHourBankBalance(false);
				setEditHourBankBalance(undefined);
				loadData();
				break;
			default:
				break;
		}
	};

	return (
		<Container className={styles['hour-bank-balance-list-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<PageTitle.Small title={t('GENERIC.TEXT.HOUR_BANK_BALANCES', undefined)} />
				</Row.Item>
			</Row>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Panel title={t('GENERIC.TEXT.FILTERS')} noTitleBorder>
						<Row>
							<Row.Item lg={12} md={12} sm={12}>
								<Container className={styles['group-buttons']}>
									<ButtonDropdown 
										title={t('GENERIC.TEXT.OPTIONS')}
										icon='cogs'
										primary
										md
										outlined>
										<ButtonDropdown.Session 
											title={t('GENERIC.TEXT.REPORTS')}
											icon='file-pdf'>
											<ButtonDropdown.Session.Item 
												title={t('GENERIC.BUTTON.DOWNLOAD.TEXT_WITH_ARGS', undefined, 'CSV')} 
												action={() => { downloadReport() }} />
										</ButtonDropdown.Session>

										<ButtonDropdown.Session 
											title={t('GENERIC.TEXT.HOUR_BANK_BALANCES')}
											icon='user-clock'>
											
											<ButtonDropdown.Session.Item 
												title={t('GENERIC.TEXT.HOUR_BANK_BALANCES')}
												action={() => { redirectToReportsHourBalances() }} />
											
											<ButtonDropdown.Session.Item 
												title={t('GENERIC.TEXT.HOUR_BANK_BALANCE_DIVERGENCES')}
												action={() => { redirectToReportsHourBalanceDivergences() }} />

										</ButtonDropdown.Session>
									</ButtonDropdown>
									{/* <Button title={t('GENERIC.BUTTON.DOWNLOAD.TEXT_WITH_ARGS', undefined, 'CSV')}
										icon="file-csv"
										primary 
										md
										loading={buttonLoading}
										action={() => { downloadReport() }} /> */}
								</Container>
							</Row.Item>

							<Row.Item lg={5} md={5} sm={12}>
								<Input type="text" fullWidth
									title={t('GENERIC.TEXT.EMPLOYEE_NAME', undefined)}
									name="employeeName"
									handleChange={employeeNameHandleChange} />
							</Row.Item>
							
							<Row.Item lg={5} md={5} sm={12}>
								<Input type="text" fullWidth
									title={t('GENERIC.TEXT.EMPLOYEE_OCCUPATION', undefined)}
									name="employeeOccupation"
									handleChange={occupationHandleChange} />
							</Row.Item>
							<Row.Item lg={2} md={2} sm={12}>
								<Select medium fullWidth
									title={t('GENERIC.TEXT.YEAR', undefined)}
									name="selectYear"
									value={yearValue.value}
									options={listYears}
									handleChange={changeSelectYear} />
							</Row.Item>
						</Row>
					</Panel>
				</Row.Item>
			</Row>

			{(hourBankBalanceStatus === 'waiting') &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Loading text={t('GENERIC.TEXT.LOADING', undefined)} />
						</Panel>
					</Row.Item>
				</Row>
			}
			{(hourBankBalanceStatus === 'error') &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<TextInformation
								icon="exclamation-circle"
								text="Não foi possível retornar informações" />
						</Panel>
					</Row.Item>
				</Row>
			}

			{hourBankBalances && hourBankBalanceStatus === 'success' && (filterHourBankBalances === undefined || filterHourBankBalances === null || filterHourBankBalances.length === 0) &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<TextInformation
								icon='search'
								text={t('HOUR_BANK_BALANCES.TEXT.NOT_FOUND', undefined)} />
						</Panel>
					</Row.Item>
				</Row>
			}

			{hourBankBalances && hourBankBalanceStatus === 'success' && !!filterHourBankBalances && filterHourBankBalances.length > 0 &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel noPadding>

							<TableListPagination
								headers={[t('GENERIC.TEXT.EMPLOYEE'), ...months.map((m:any) => m.value), t('GENERIC.TEXT.OPTIONS')]}
								columns={[
									'employeeName',
									'january',
									'february',
									'march',
									'april',
									'may',
									'june',
									'july',
									'august',
									'september',
									'october',
									'november',
									'december',
									'options'
								]}
								handleColumnRender={handleColumnRender}
								list={filterHourBankBalances} />
						</Panel>
					</Row.Item>
				</Row>
			}

			{
			showModalEditHourBankBalance && 
			<Modal lg
				title={`Editar banco - ${editHourBankBalance!.employeeName} - ano ${yearValue.value}`}
				showClose
				handleClose={() => setShowModalEditHourBankBalance(false)}>
					<HourBankBalanceEdit 
						hourBankBalanceRaw={editHourBankBalance!}
						handleChanges={(event: ComponentEvent) => internalHandleChanges(event)}/>
			</Modal>
			}
		</Container>
	);
}

export default List;