import { getEnvironment } from "../services/auth.services";
import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

export const copyObjectJSON= (object:any) => {
	if(!!object){
		return JSON.parse(JSON.stringify(object));
	}
	return object;
};

export const calculateCycleOfEnvironment = (year:any) => {

	let month:{key:string,value: string, startDay: string, endDay: string}[] = [];

	//TODO enviar valores por parametro (colocar o valor no context do react no lugar do sessionstore)
	const json = getEnvironment();

	const cycliesYear = json.cyclies[year];

	return cycliesYear.map((c:any) => ({
		key: c.cycle,
		value: c.text,
		startDay: c.startDay,
		endDay: c.endDay,
	}));


	// const currentDate = new Date();
	// let currentMonth = currentDate.getMonth();
	// //Months
	// const january = t('GENERIC.MONTHS.JANUARY');
	// const february = t('GENERIC.MONTHS.FEBRUARY');
	// const march = t('GENERIC.MONTHS.MARCH');
	// const april = t('GENERIC.MONTHS.APRIL');
	// const may = t('GENERIC.MONTHS.MAY');
	// const june = t('GENERIC.MONTHS.JUNE');
	// const july = t('GENERIC.MONTHS.JULY');
	// const august = t('GENERIC.MONTHS.AUGUST');
	// const september = t('GENERIC.MONTHS.SEPTEMBER');
	// const october = t('GENERIC.MONTHS.OCTOBER');
	// const november = t('GENERIC.MONTHS.NOVEMBER');
	// const december = t('GENERIC.MONTHS.DECEMBER');

	// if(json.scaleConfigs && json.scaleConfigs.customCycleOfScale=== true){
	// 	const startDay = json.scaleConfigs.cycleOfScaleStart;
	// 	const endDay = json.scaleConfigs.cycleOfScaleEnd;

	// 	let stringMonth = startDay + ' %s - ' + endDay + ' %x';

	// 	for (let index = 0; index < 12; index++) {
	// 		let startDateCicle = new Date(currentDate.getFullYear(), index, startDay);
	// 		let endDateCicle = new Date(currentDate.getFullYear(), index, endDay);
	// 		endDateCicle.setMonth(+1);

	// 		if(index === 11){
	// 			//Verifica ano passado
	// 			startDateCicle.setFullYear(-1);
	// 			if(currentDate >= startDateCicle && currentDate <= endDateCicle){
	// 				currentMonth = index;
	// 			}

	// 			//Verifica ano atual
	// 			startDateCicle.setFullYear(+1);
	// 			endDateCicle.setFullYear(+1);
	// 			if(currentDate >= startDateCicle && currentDate <= endDateCicle){
	// 				currentMonth = index;
	// 			}
	// 		}else{

	// 			if(currentDate >= startDateCicle && currentDate <= endDateCicle){
	// 				currentMonth = index;
	// 			}
	// 		}
			
	// 	}
        
	// 	month = [
	// 		{key:'january', value: stringMonth.replace('%s',january).replace('%x', february), startDay: '01-'+startDay, endDay: '02-'+endDay},
	// 		{key:'february', value: stringMonth.replace('%s',february).replace('%x', march), startDay: '02'+startDay, endDay: '03-'+endDay},
	// 		{key:'march', value: stringMonth.replace('%s',march).replace('%x', april), startDay: '03-'+startDay, endDay: '04-'+endDay},
	// 		{key:'april', value: stringMonth.replace('%s',april).replace('%x', may), startDay: '04-'+startDay, endDay: '05-'+endDay},
	// 		{key:'may', value: stringMonth.replace('%s',may).replace('%x', june), startDay: '05-'+startDay, endDay: '06-'+endDay},
	// 		{key:'june', value: stringMonth.replace('%s',june).replace('%x', july), startDay: '06-'+startDay, endDay: '07-'+endDay},
	// 		{key:'july', value: stringMonth.replace('%s',july).replace('%x', august), startDay: '07-'+startDay, endDay: '08-'+endDay},
	// 		{key:'august', value: stringMonth.replace('%s',august).replace('%x', september), startDay: '08-'+startDay, endDay: +'09-'+endDay},
	// 		{key:'september', value: stringMonth.replace('%s',september).replace('%x', october), startDay: '09-'+startDay, endDay: '10'+endDay},
	// 		{key:'october', value: stringMonth.replace('%s',october).replace('%x', november), startDay: '10-'+startDay, endDay: '11-'+endDay},
	// 		{key:'november', value: stringMonth.replace('%s',november).replace('%x', december), startDay: '11-'+startDay, endDay: '12-'+endDay},
	// 		{key:'december', value: stringMonth.replace('%s',december).replace('%x', january), startDay: '12-'+startDay, endDay: '01-'+endDay},
	// 	];
	// }else{
	// 	month = [
	// 		{key:'january', value: january, startDay: '01-01', endDay:'01-31'},
	// 		{key:'february', value: february, startDay: '02-01', endDay:'02-28'},
	// 		{key:'march', value: march, startDay: '03-01', endDay:'03-31'},
	// 		{key:'april', value: april, startDay: '04-01', endDay:'04-30'},
	// 		{key:'may', value: may, startDay: '05-01', endDay:'05-31'},
	// 		{key:'june', value: june, startDay: '06-01', endDay:'06-30'},
	// 		{key:'july', value: july, startDay: '07-01', endDay:'07-31'},
	// 		{key:'august', value: august, startDay: '08-01', endDay:'08-31'},
	// 		{key:'september', value: september, startDay: '09-01', endDay:'09-30'},
	// 		{key:'october', value: october, startDay: '10-01', endDay:'10-31'},
	// 		{key:'november', value: november, startDay: '11-01', endDay:'11-30'},
	// 		{key:'december', value: december, startDay: '12-01', endDay:'12-31'},
	// 	];
	// }


	return month;
}

const converterStringCycleToDate = (month: string, year: string) => {



}