import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button, Panel, Loading, Row, Modal, PageTitle,Label, TextInformation, Toast, Input, AlertTiny, Timeline } from '@optimuminterfaces/revex-react-components/dist/components/index';
import ListPagination from './ListPagination/ListPagination';
import New from '../New/New';

import styles from './List.module.scss';
import { deleteSrepParams, fetchSrepParams, postNewSrepParams } from '../../../../services/srepParams.services';
import { SrepParam } from '../../../../models/SrepParam';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';
import { getEnvironment } from '../../../../services/auth.services';
import { formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import TableListPagination from '../../../organism/TableListPagination/TableListPagination';

interface ListProps{
	Container?: any,
	hasPermissionCreateSrepParams?: boolean,
	hasPermissionUpdateSrepParams?: boolean,
	hasPermissionDeleteSrepParams?: boolean
}

const List = ({ Container = 'div', 
				hasPermissionCreateSrepParams=true, 
				hasPermissionUpdateSrepParams=true, 
				hasPermissionDeleteSrepParams=true }: ListProps) => {

	const [srepParams,setSrepParams] = useState<SrepParam[]>();
	const [srepParamsStatus,setSrepParamsStatus] = useState('waiting');
	const [showModalNewSettings, setShowModalNewSettings] = useState<boolean>(false);
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
    const [srepParamIdForDelete, setSrepParamIdForDelete] = useState<string|null>(null);
	const [srepParamIndexForDelete, setSrepParamIndexForDelete] = useState<number|null>(null);
    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const [statusNewSrep, setStatusNewSrep] = useState<string>('success');
    const [messageNewSrep, setMessageNewSrep] = useState<string|null>(null);

	const json = getEnvironment();
    
    let navigate = useNavigate();

	useEffect(() => {
		(async () => {
			try{
				const jsonReturned =  await fetchSrepParams();
				if(jsonReturned){
					setSrepParams(jsonReturned.srepParams)
					setSrepParamsStatus('success');
				}else{
					setSrepParamsStatus('error');
				}
			}catch(error){
				console.log(error);
			}

		})();
    }, []);

    useEffect(() => {
        if (!!messageNewSrep) {
            setTimeout(() => {
                setMessageNewSrep(null);
            }, 5000);
        }
    }, [messageNewSrep]);

	const newSrepConfiguration = async (nameSrepConfiguration:string, startDateSrepConfiguration:string, copyBySrepParamId:string) => {
		try{
			const jsonReturned =  await postNewSrepParams({name: nameSrepConfiguration, startDate: startDateSrepConfiguration, copyBySrepParamId: copyBySrepParamId});
			if(jsonReturned){
				let srep = [...srepParams!];
				srep.push(jsonReturned.data.srepParam)

				setSrepParams(srep)

            	setMessageNewSrep(jsonReturned.message);
			}else{
				setMessageNewSrep(t('GENERIC.TEXT.OPERATION_PROBLEM'));
			}
			setShowModalNewSettings(false);
		}catch(error){
			console.log(error);
		}

    };

    const openModalDelete = (srepParamId:string, index: number) => {
        setSrepParamIdForDelete(srepParamId);
		setSrepParamIndexForDelete(index);
        setShowModalDelete(true);
    };

    const deleteSrepParam = async (srepParamId:string, index:number) => {
        setLoadingButton(true);
		try{
			const jsonReturned =  await deleteSrepParams(srepParamId);
			if(jsonReturned){
				let srep = [...srepParams!];
				srep.splice(index,1);

				setSrepParams(srep)

				setSrepParamIdForDelete(null);
				setSrepParamIndexForDelete(null);
				setLoadingButton(false);
            	setMessageNewSrep(jsonReturned.message);
			}else{
				setMessageNewSrep(t('GENERIC.TEXT.OPERATION_PROBLEM'));
			}
			setShowModalDelete(false);
			setLoadingButton(false);
		}catch(error){
			console.log(error);
		}


    };

	return (
        <Container className={styles['srep-params-list-rt']}>
            <Row>
                <Row.Item lg={12} md={12} sm={12}>
                    <PageTitle.Small title={t('SREP.LIST.SREP_CONFIGURATION')} />
				</Row.Item>
				<Row.Item lg={12} md={12} sm={12}>
				<Panel className={styles['panel-list']} title={'Configuração de Dias do Período Customizado (Inicio - Fim)'} noTitleBorder>
					<>
					<TableListPagination
						showPagination={false}  
						headers={['Período', 'Data inicio da configuração', 'Data final da configuração']}
						columns={['period', 'startDate', 'endDate']} 
						list={json.instanceConfiguration.closeCycle} 
						handleColumnRender={(columnValue:any, columnName:string, columnIndex:number, row: any ) =>{
							switch(columnName){
								case 'period':
									return row.isCycle?`Inicio dia ${row.startDay} até dia ${row.endDay} do próximo mês`: 'Mensal'
								case 'startDate':
								case 'endDate':
									return columnValue === undefined? '-' : formatLocalDate(columnValue,true,undefined,undefined)

							default:
								return columnValue;
						}}}/>
					</>

				</Panel>
                    <Panel className={styles['panel-list']} title={t('SREP.LIST.SREP_CONFIGURATION')} noTitleBorder noPadding>
                        <>
							{hasPermissionCreateSrepParams &&
								<Row>
									<Row.Item lg={12} md={12} sm={12} className={styles['panel-settings-actions']}>
                                        
										<Container className={styles['actions']}>
                                            <Button 
												title={t('CABLE.POINT_EVENT.LIST.TITLE')}
												primary 
												md 
												
												action={() => navigate(`/srepsMF/pointEvents/`) } />

											<Button 
												title={t('SREP.LIST.NEW_CONFIGURATION')}
												success 
												md 
												icon='plus'
												action={() => setShowModalNewSettings(true)} />

                                           
										</Container>
									</Row.Item>
								</Row>
							}

							{!!messageNewSrep &&
								<Row>
									<Row.Item lg={12} md={12} sm={12}>
										<Toast 
												danger={statusNewSrep === 'error'} 
												success={statusNewSrep === 'success' ? true : false} 
												title={t('GENERIC.TEXT.OPERATION')}
												positionTopRight
												message={messageNewSrep} 
												showTime={5000}/>
										
									</Row.Item>
								</Row>
							}
							{(!srepParams && srepParamsStatus === 'waiting') && 
								<Row>
									<Row.Item lg={12} md={12} sm={12}>
										<Loading/>
									</Row.Item>
								</Row>
							}
							{(!srepParams && srepParamsStatus === 'error') && 
								<Row>
									<Row.Item lg={12} md={12} sm={12}>
										<TextInformation 
											icon="exclamation-circle"
											text={t('GENERIC.TEXT.UNABLE_RETURN')} />
									</Row.Item>
								</Row>
							}
							{(srepParams && srepParamsStatus === 'success' && srepParams.length === 0) &&
								<Row>    
									<Row.Item lg={12} md={12} sm={12}>
										<TextInformation 
											icon="search"
											text={t('SREP.LIST.NO_CONFIGURATION_REGISTERED')} />
									</Row.Item>
								</Row>
							}
							{(srepParams && srepParamsStatus === 'success' && srepParams.length > 0) &&
								<Row>
									<Row.Item lg={12} md={12} sm={12}>
										<ListPagination 
											list={srepParams}
											hasPermissionUpdateSrepConfiguration={hasPermissionUpdateSrepParams}
											hasPermissionDeleteSrepConfiguration={hasPermissionDeleteSrepParams}
											actionDelete={openModalDelete} />
									</Row.Item>
								</Row>
							} 
						</>
                    </Panel>
                </Row.Item>
            </Row>

            {showModalNewSettings &&
                <Modal
                    title={t('SREP.LIST.NEW_CONFIGURATION')}
                    xs
					className={styles['modal-new-Settings']}
                    showClose
                    handleClose={() => { setShowModalNewSettings(false) }}>

                    <New 
                        noPanel 
                        listSrepConfigurations={(srepParams && srepParamsStatus === 'success' && srepParams.length > 0) ? srepParams : []}
                        saveAction={newSrepConfiguration}
                        cancelAction={() => setShowModalNewSettings(false)} /> 

                </Modal>
            }

            {showModalDelete && srepParamIdForDelete !== null && 
                <Modal
                    title={t('SREP.LIST.DELETE_CONFIGURATION')}
                    xs
                    showClose
                    handleClose={() => {
                        setShowModalDelete(false);
                        setSrepParamIdForDelete(null);
                    }}>
                    <Container className={styles['modal-delete-srep']}>
                        <Label title={t("SREP.LIST.DELETE_CONFIGURATION_CONFIRMATION")}/>
                        <Container>
                            <Button
                                primary
                                title={t('GENERIC.TEXT.NO')}
                                outlined
                                action={() => {
                                    setShowModalDelete(false);
                                    setSrepParamIdForDelete(null);
                                }} />

                            <Button
                                danger
                                title={t('GENERIC.TEXT.YES')}
                                icon="trash"
                                loading={loadingButton}
                                action={() => deleteSrepParam(srepParamIdForDelete, srepParamIndexForDelete!)} />
                        </Container>
                    </Container>
                </Modal>
            }

        </Container>    
    );
}

export default List;